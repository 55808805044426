<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <div>
        <template>
          <div class="card card-transparent">
            <div class="card-header search-container">
              <div class="search-content">
                <div class="row mt-2 c_filter_area">
                  <div class="col-xl-4 col-lg-4 col-sm-4">
                    <div class="row">
                      <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                        <button :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getPrevMonth"><i class="fa fa-angle-left c_prev_btn"></i>{{ $t('sale.prevMonth') }}</button>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-3 col-sm-12">
                        <month-picker-input
                          ref="month"
                          :class="['c_sale_picker', isDisableButton ? 'c_disable' : '']"
                          lang="ja"
                          :default-month="selectMonth.month"
                          :default-year="selectMonth.year"
                          @change="changeMonth"
                          :show-year="true"
                          input-pre-filled
                          date-format="%Y年%n"
                        >
                        </month-picker-input>
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-0 col-sm-0">
                        <button :disabled="isDisableButton" class="btn c_prev_month c_z_index_11" @click="getNextMonth">
                          {{ $t('sale.nextMonth') }}<i class="fa fa-angle-right c_next_btn"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
                  <div class="col-xl-4 col-lg-4 col-sm-4 pl-0">
                    <app-select
                      :name="filters.apartment_id_equal.name"
                      :disabled="isDisableApartment"
                      input-style="normal"
                      v-model="filters.apartment_id_equal.value"
                      :options-data="apartments"
                    />
                  </div>
                  <div class="col-xl-3 col-lg-3 col-sm-3 pl-0 d-flex justify-content-end">
                    <button v-if="totalPrice"
                            class="btn btn-primary c_z_index_11"
                            @click="handleBtnDetailClick()">
                      {{ $t('sale.detailPrice') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 pb-5 c_total_area">
            <div class="col-xl-4 col-lg-4 col-sm-4 pt-3"
                 style="margin-left: 7px; margin-right: -7px; padding-right: 0; padding-left: 0;"
            >
              <div class="c_background_gray c_border_radius_1 p-4">
                <label>売上合計</label>
                <h3 class="c_text_align_center c_font_size_40px">￥{{ totalPrice ? totalPrice : '--' }}</h3>
              </div>
              <div class="c_background_gray c_border_radius_1 p-4 mt-4">
                <label>件数</label>
                <h3 class="c_text_align_center c_font_size_40px">{{ totalRecord ? totalRecord : '--' }}件</h3>
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
            <div class="col-xl-7 col-lg-7 col-sm-7 pl-0 pt-3">
              <fieldset class="c_chart_area">
                <div class="row mt-2 pb-2 pl-3">
                  <div class="col-xl-12 col-lg-12 col-sm-12 pl-0 font-weight-bold">{{ $t('sale.3month') }}</div>
                </div>
                <div id="chart" ref="chart" style="width: 100%;height:100%;"></div>
              </fieldset>
            </div>
          </div>

          <div class="row mt-2 pb-5 c_total_area">
            <div class="col-xl-4 col-lg-4 col-sm-4 pt-3">

            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
            <div class="col-xl-7 col-lg-7 col-sm-7 pl-0">
              <fieldset class="c_pie_area">
                <div class="row mt-2 pb-2 pl-3">
                  <div class="col-xl-12 col-lg-12 col-sm-12 pl-0 font-weight-bold">{{ $t('sale.byFacility') }}</div>
                </div>
                <div style="width: 100%; height:40vh;">
                  <div id="chartPie" ref="chartPie" v-show="isShowChartPie" style="width: 100%; height:100%;"></div>
                </div>
              </fieldset>

              <div class="table-responsive">
                <table class="table app-table-list">
                  <thead class="thead-light">
                  <tr>
                    <template v-for="(column, index) in columns">
                      <slot :name="`header-cell-${column.name}`">
                        <th
                          :key="index"
                          :class="['app-sort', column.class]"
                        >
                          {{ column.label }}
                        </th>
                      </slot>
                    </template>
                  </tr>
                  </thead>
                  <tbody id="appTableList">
                  <tr v-if="!entries.length">
                    <td colspan="100">
                        <span>
                          {{ $t("common.list empty") }}
                        </span>
                    </td>
                  </tr>
                  <tr :key="entry.id" :id="entry.id" :data-id="entry.id" v-for="(entry, index) in entries">
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td :title="entry.name" class="c_3_dot">{{ entry.name }}</td>
                    <td style="text-align: right" v-if="entry.amount > 0">￥{{ entry.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') }}</td>
                    <td style="text-align: right" v-else>￥{{ entry.amount_show.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') }}</td>
                    <td style="text-align: center">{{ entry.number_item.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') }}</td>
                    <td style="text-align: center" v-if="entry.amount > 0">{{ index == 0 ? (100 - (entries[1] ? entries[1].percent : 0) - (entries[2] ? entries[2].percent : 0)).toFixed(2) : entry.percent }}%</td>
                    <td style="text-align: center" v-else>-</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import SaleListTable from "./SaleListTable";
import {ENDPOINT} from "../../../constants/api";
import {MonthPickerInput} from 'vue-month-picker'
import moment from 'moment'

const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SEARCH = 'EVENT_SEARCH';

import * as echarts from 'echarts';

var polarChart;
var pieChart;

export default {
  name: "SaleListPage",
  components: {
    "app-basic-table": SaleListTable,
    MonthPickerInput,
  },
  data() {
    return {
      selectMonth: {
        month: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).month() + 1 : moment().month() + 1,
        year: this.$route.query['filters.month.equal'] ? moment(this.$route.query['filters.month.equal']).year() : moment().year(),
      },
      filters: {
        month: {
          name: "month",
          condition: "equal",
          label: this.$t("reservation.date"),
          value: moment().format('YYYY/MM/01')
        },
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("common.mansion"),
          value: this.$route.query['filters.apartment_id.equal'] ?? 'all'
        },
      },
      monthList: [
        moment().subtract(2, 'months').format('M月'),
        moment().subtract(1, 'months').format('M月'),
        moment().format('M月')
      ],
      columns: [
        {name: "index", label: this.$t("common.index"), textAlign: 'text-center', class: 'c_w_4_percent'},
        {name: "apartment_id", label: this.$t("sale.facility"), textAlign: 'text-center', class: 'c_w_40'},
        {name: "amount", label: this.$t("sale.price"), textAlign: 'text-center', class: 'c_w_40'},
        {name: "number_item", label: this.$t("sale.records"), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "percent", label: this.$t("sale.percent"), textAlign: 'text-center', class: 'c_w_10_percent'},
      ],
      totalPrice: 0,
      isShowChartPie: true,
      apartments: [],
      entries: [],
      totalRecord: 0,
      currentMonth: {
        price: 0,
        record: 0
      },
      prevMonth: {
        price: 0,
        record: 0
      },
      multiPrevMonth: {
        price: 0,
        record: 0
      },
      isDisableButton : false,
      isDisableApartment : false,
      numberApartment: 0,
    };
  },
  methods: {
    createChart() {
      let chartDom = this.$refs.chart
      polarChart  = echarts.init(chartDom);
      let option;
      const colors = ['#057ED3', '#77B008'];
      option = {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '15%',
          left: '20%',
        },
        toolbox: {
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {show: false}
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            // prettier-ignore
            data: this.monthList
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.$t("sale.totalPrices"),
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
          },
          {
            type: 'value',
            name: this.$t("sale.totalRecords"),
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }},
        ],
        series: [
          {
            name: '合計',
            type: 'bar',
            barWidth: '40%',
            data: [
              {
                value: this.multiPrevMonth.price,
                itemStyle: {
                  color: '#D9D9D9'
                },
              },
              {
                value: this.prevMonth.price,
                itemStyle: {
                  color: '#D9D9D9'
                }
              },
              {
                value: this.currentMonth.price,
                itemStyle: {
                  color: colors[0]
                }
              }
            ]
          },
          {
            name: '件数',
            type: 'line',
            color: [colors[1]],
            yAxisIndex: 2,
            data: [
              this.multiPrevMonth.record, this.prevMonth.record, this.currentMonth.record
            ]
          }
        ]
      };
      option && polarChart.setOption(option);
    },
    createChartPie() {
      let chartDomPie = this.$refs.chartPie
      pieChart = echarts.init(chartDomPie);
      let option;
      const legendData = [];
      const seriesData = [];

      if (this.entries.length > 0) {
        if (this.entries[0] && this.entries[0].name) {
          legendData.push(this.entries[0].name);
        }
        if (this.entries[1] && this.entries[1].name) {
          legendData.push(this.entries[1].name);
        }
        if (this.entries[2] && this.entries[2].name) {
          legendData.push(this.entries[2].name);
        }

        if (this.entries[0]) {
          seriesData.push({
            name: this.entries[0].name,
            value: this.entries[0].amount,
            itemStyle: {
              color: '#DF8091'
            },
          });
        }
        if (this.entries[1]) {
          seriesData.push({
            name: this.entries[1].name,
            value: this.entries[1].amount,
            itemStyle: {
              color: '#95DEC7'
            },
          });
        }
        if (this.entries[2]) {
          seriesData.push({
            name: this.entries[2].name,
            value: this.entries[2].amount,
            itemStyle: {
              color: '#E09B6A'
            },
          });
        }
      }
      option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ': ￥' + params.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 80,
          top: 20,
          data: legendData,
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['40%', '50%'],
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          },
        ]
      };

      option && pieChart.setOption(option);

    },
    async getDataTotal() {
      this.monthList = [
        moment(this.filters.month.value).subtract(2, 'months').format('M月'),
        moment(this.filters.month.value).subtract(1, 'months').format('M月'),
        moment(this.filters.month.value).format('M月')
      ]
      this.resetTotalData();
      await this.$request.get(ENDPOINT.SALE_TOTAL, {month: this.filters.month.value, apartment_id: this.filters.apartment_id_equal.value}).then((res) => {
        if (!res.hasErrors()) {
          res.data.data.map((item) => {
            //current month
            if (item.month === moment(this.filters.month.value).format('YYYYMM')) {
              this.totalPrice = item.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              this.totalRecord = item.number_item.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              this.currentMonth = {
                price: item.amount,
                record: item.number_item,
              }
            } else if (item.month === moment(this.filters.month.value).subtract(1, 'months').format('YYYYMM')) {
              this.prevMonth = {
                price: item.amount,
                record: item.number_item,
              }
            } else if (item.month === moment(this.filters.month.value).subtract(2, 'months').format('YYYYMM')) {
              this.multiPrevMonth = {
                price: item.amount,
                record: item.number_item,
              }
            }
          })
        }
        this.createChart()
      })
    },
    resetTotalData() {
      this.totalPrice = 0
      this.totalRecord = 0
      this.currentMonth = {
        record: 0,
        price: 0
      }
      this.prevMonth = {
        record: 0,
        price: 0
      }
      this.multiPrevMonth = {
        record: 0,
        price: 0
      }
    },
    async getPrevMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let prevMonth = moment(currentMonth).subtract(1, 'months')
      this.selectMonth.month = prevMonth.month() + 1
      this.selectMonth.year = prevMonth.year()
      this.filters.month.value = prevMonth.format('YYYY/MM/01')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
    async changeMonth(dataSelect) {
      this.selectMonth = {
        month: dataSelect.monthIndex,
        year: dataSelect.year,
      }
      let currentMonth = dataSelect.year + '/' + dataSelect.monthIndex + '/01'
      this.filters.month.value = currentMonth
      // this.$refs.month.selectedDate = moment(currentMonth).format('YYYY年MM月')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
    async getNextMonth() {
      let currentMonth = this.selectMonth.year + '/' + this.selectMonth.month + '/01'
      let nextMonth = moment(currentMonth).add(1, 'months')
      this.selectMonth.month = nextMonth.month() + 1
      this.selectMonth.year = nextMonth.year()
      this.filters.month.value = nextMonth.format('YYYY/MM/01')
      // this.$refs.month.selectedDate = nextMonth.format('YYYY年MM月')
      await this.updateRouteQuery(EVENT_SEARCH)
    },
    async updateRouteQuery(events) {
      this.isDisableButton = true
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      _.forEach(events, event => {
        if (event === EVENT_SEARCH) {
          const filters = _.cloneDeep(this.filters);
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.condition] = filter.value.toString().trim()
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.condition];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        await this.getPieData()
        await this.getDataTotal()
      }
      this.isDisableButton = false
    },
    async getPieData() {
      let params = {}
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })

      if (this.beforeFetch) {
        let response = await this.$request.get(this.beforeFetch, this.paramsBefore);
        this.$emit('beforeFetch', response);
      }

      const res = await this.$request.get(ENDPOINT.SALE_LIST_DETAIL, {
        apartment_id: this.filters.apartment_id_equal.value,
        month: this.filters.month.value,
      })
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data;
        if (this.entries.length == 0) {
          this.isShowChartPie = false
        } else {
          this.isShowChartPie = true
        }
        this.createChartPie()
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          // Count number of paragraph
          let paras = $(el).text().split('\n');
          let count = 0;
          $.each(paras, (index, value) => {
            count++;
          });

          // Show tooltip
          if ((count >= 3) || (el.offsetWidth < el.scrollWidth)) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
    },
    getParamsFilters(params) {
      this.$request.get(this.ENDPOINT.UI_HELPER_RESERVATION, params).then(res => {
        this.numberApartment = res.data.apartments.length
        if ( this.numberApartment == 1) {
          this.isDisableApartment = true
          this.filters.apartment_id_equal.value = res.data.apartments[0].id
        } else {
          this.isDisableApartment = false
        }
        this.apartments = res.data.apartments
        this.apartments.unshift({
          id: "all",
          name: ""
        })
      })
    },
    handleBtnDetailClick() {
      this.$router.push({
        name: this.ROUTES.ADMIN.SALE_LIST_DETAIL_APARTMENT,
        query: {
          "filters.apartment_id.equal": this.filters.apartment_id_equal.value,
          "filters.month.equal": this.$route.query['filters.month.equal'],
          "prev":"detailApartment"
        }
      });
    },
  },
  watch: {
    "$route.query": {
      handler: async function (after, before) {
        if (!_.isEqual(after, before)) {
          await this.updateRouteQuery(EVENT_SEARCH)
        }
      },
      deep: true,
    },
    "filters.apartment_id_equal.value": {
      handler: async function (after, before) {
        if (after === 'all') {
          await this.$router.push({
            name: this.ROUTES.ADMIN.SALE_LIST,
            query: {
              "filters.apartment_id.equal": 'all',
              "filters.month.equal": this.filters.month.value ?? moment().format('YYYY/MM/01'),
            }
          })
        } else {
          if(this.numberApartment > 1) {
            await this.updateRouteQuery(EVENT_SEARCH)
          }
        }
      },
    },
  },
  mounted: async function () {
    this.getParamsFilters({apartment_id: 'all'})
  },
}
window.addEventListener('resize',function(){
  polarChart.resize();
  pieChart.resize();
})
</script>
<style scoped lang="scss">
.c_prev_month {
  width: 100px;
}
.c_font_size_40px {
  font-size: 40px;
}
.c_chart_area {
  height: 100%;
  border: 1px solid #d1cdcd;
  #chart {
    //padding-left: 8%;
  }
}
.c_pie_area {
  border: 1px solid #d1cdcd;
  #chart {
    //padding-left: 8%;
  }
}

.c_background_gray {
  background-color: #FAFAFA;
  height: 46%;
}

.c_text_align_center {
  text-align: center;
  font-weight: bold;
}

.c_border_radius_1 {
  border-radius: 10px;
}

.c_w_30_percent {
  width: 30%;
}

.c_w_10_percent {
  width: 10%;
}
.c_w_4_percent {
  width: 4%;
}

.app-table-container p.app-table-p.text-break {
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre;
}
.c_disable {
  pointer-events: none;
  opacity: 1;
}
.c_z_index_11 {
  z-index: 11;
}
table {
  border-left: 1px solid #d1cdcd;
  border-bottom: 1px solid #d1cdcd;
  border-right: 1px solid #d1cdcd;
}
.c_back_ground_white {
  background-color: white;
}
.c_3_dot {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
button[disabled] {
  cursor: pointer;
}
</style>
